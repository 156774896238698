@import '@uppy/core/dist/style.css';
@import '@uppy/dashboard/dist/style.css';
@import '@uppy/webcam/dist/style.css';
@import '@uppy/screen-capture/dist/style.css';
@import '@uppy/progress-bar/dist/style.css';
@import '@uppy/url/dist/style.css';

/* Uppy Overrides */

[data-uppy-drag-drop-supported=true] .uppy-Dashboard-AddFiles {
  border: none;
}

.uppy-Dashboard-AddFiles-title {
  display: none;
}

/* Allow modules to use the full screen on mobile */
.uppy-Root, .uppy-Dashboard-inner, .uppy-Dashboard-innerWrap {
  position: static;
}
@media (max-width: 768px) {
  .uppy-DashboardContent-panel {
    position: fixed;
    border-radius: 0;
  }
}

/* Make the dashboard pills instead of a list / grid */
[data-uppy-drag-drop-supported=true] .uppy-Dashboard-AddFiles {
  height: auto;
}
.uppy-Dashboard-inner {
  height: auto !important;
}
.uppy-Dashboard-inner {
  width: 100%;
  border: none;
  background-color: transparent;
}
.uppy-Dashboard-AddFiles, [data-uppy-drag-drop-supported=true] .uppy-Dashboard-AddFiles {
  display: block;
  margin: 0;
}
.uppy-Dashboard-AddFiles-list, .uppy-size--md .uppy-Dashboard-AddFiles-list {
  max-width: 100%;
  margin-top: 0;
  overflow-y: visible;
  align-items: center;
  justify-content: start;
}
.uppy-DashboardTab {
  display: inline-block;
  flex-grow: 1;
  width: fit-content;
  margin-left: 5px;
  margin-bottom: 5px;
  border-bottom-width: 0;
  align-self: center;
}
.uppy-Dashboard-AddFiles-list {
  flex-direction: row;
  flex-wrap: wrap;
}

.uppy-Dashboard-AddFiles-list > .uppy-DashboardTab:first-of-type {
  margin-left: 0;
}
.uppy-DashboardTab-btn, .uppy-size--md .uppy-DashboardTab-btn {
  display: block;
  width: 100%;
  padding: 6px;
  padding-right: 10px;
  border-radius: 0.25rem;
  background: #F1F3F6;
}
@media (max-width: 768px) {
  .uppy-DashboardTab-btn svg {
    display: none;
  }
}
.uppy-DashboardTab-btn:not(:hover) svg rect {
  fill: #CCC;
}
.uppy-DashboardTab-btn:hover svg rect {
  transition: fill 0.5s ease-in-out;
}
.uppy-DashboardTab-btn:hover {
  background: rgb(226 220 221);
}
[dir="ltr"] .uppy-size--md .uppy-DashboardTab-btn svg {
  margin-right: 8px;
}
.uppy-DashboardTab-name, .uppy-size--md .uppy-DashboardTab-name {
  display: inline-block;
  margin: 3px 0;
  font-weight: bold;
}
@media (min-width: 787px) {
  .uppy-DashboardContent-panel {
    position: relative;
    margin-top: 5px;
  }
}
.uppy-DashboardContent-panelBody {
  padding: 20px 10px;
}
.uppy-Dashboard-innerWrap {
  display: block;
}
.uppy-Dashboard-AddFiles-list:before {
  content: "Add photos / videos from";
  display: inline-block;
  align-self: center;
  width: 8rem;
  margin-bottom: 10px;
  margin-left: 5px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.2;
  color: #666;
}

.uppy-ProgressBar {
  top: auto;
  bottom: 0;
  height: 2rem;
  background: #dcdee2;
}
.uppy-ProgressBar-inner:after {
  content: "Uploading...";
  padding: 0.25rem;
  text-align: center;
  position: absolute;
  width: 100%;
  font-weight: bold;
  color: white;
}
.uppy-ProgressBar-percentage {
  display: none;
}
.uppy-ProgressBar-inner {
  background: rgb(151 192 202);
  box-shadow: 0 0 10px rgb(151 192 202 / 70%);
}

@media (max-width: 768px) {
  .uppy-Dashboard-AddFiles-list > span {
    display: flex;
    flex: 1 1;
  }
  .uppy-Dashboard-AddFiles-list:before {
    margin-bottom: 5px;
  }
}

/* App CSS */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.uppy-Dashboard-inner {
  margin: auto;
}
